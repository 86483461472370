
import * as Sentry from '@sentry/browser'
import { defineComponent, ref, reactive, toRefs, onBeforeMount } from 'vue'
import {
  get as getOrderQtDnsConfirm,
  Confirm,
} from '@/api/customer/order/qt-dns/confirm'
// import { post as postOrderByNewCard } from '@/api/customer/order/card/new'
import { useRoute } from 'vue-router'
import { useTranslation } from '@/locales'

import Invoice from '@/components/Invoice.vue'

export default defineComponent({
  components: {
    Invoice,
  },
  setup() {
    const items = reactive<{ invoice: Confirm | undefined }>({
      invoice: undefined,
    })
    const { invoice } = toRefs(items)
    const route = useRoute()
    const noService = ref('')
    const { t } = useTranslation()
    onBeforeMount(() => {
      // TODO: Get paymentFrequncy from query
      const paymentFrequency = 'monthly'
      console.log(route.query)
      getOrderQtDnsConfirm(paymentFrequency)
        .then((res) => {
          invoice.value = res
        })
        .catch(() => {
          noService.value = t('There is not available services now')
          invoice.value = undefined
        })
    })

    // TODO: Path paymentFrequency from query
    const exec = () => {
      // postOrderByNewCard('monthly', 'qt-dns')
      console.log('Clicked!')
    }

    return {
      invoice,
      noService,
      t,
      exec,
    }
  },
})
