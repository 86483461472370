import { errorHandler } from '@/api/utils'
import { http } from '@/plugins/ky'

export type Service = {
  serviceName: string
  mount: number
  ratePrice: string
  total: string
}

export type Confirm = {
  customerName: string
  customerAddress: string
  currentDate: string
  services: Service[]
  total: string
  salesTax: string
  totalWithTax: string
}

export const get = (paymentFrequency: string) =>
  new Promise<Confirm>((resolve, reject) =>
    http
      .get('customer/order/qt-dns/confirm', {
        searchParams: {
          ['payment_frequency']: paymentFrequency,
        },
      })
      .then(async (r: any) => {
        const res = await r.json()
        resolve(res)
      })
      .catch(async ({ response, message }: any) => {
        console.log(response, message)
        await errorHandler(response, message)
        reject(undefined)
      })
  )
