
import * as Sentry from '@sentry/browser'
import { defineComponent, ref, PropType } from 'vue'
import { Confirm } from '@/api/customer/order/qt-dns/confirm'
import { useTranslation } from '@/locales'

export default defineComponent({
  props: {
    confirm: Object as PropType<Confirm | undefined>,
    noService: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const processing = ref(false)
    const { t } = useTranslation()

    const exec = () => {
      processing.value = true
      props
        .onClick()
        .then(() => (processing.value = false))
        .catch(() => (processing.value = false))
    }

    return {
      t,
      exec,
    }
  },
})
