<template>
  <div class="flex justify-center items-center">
    <div class="mt-8">
      <div v-if="confirm != null">
        <div class="flex justify-between md:mr-20">
          <p class="text-left text-xs text-gray-500 pb-2">
            {{ t('Billing to') }}
          </p>
          <p class="text-left text-xs text-gray-500 pb-2">
            {{ confirm.currentDate }}
          </p>
        </div>

        <h1 class="text-left mb-1 text-2xl font-bold">
          {{ confirm.customerName }}
        </h1>

        <p class="text-xs md:text-sm text-gray-500 mb-8 text-left">
          {{ confirm.customerAddress }}
        </p>

        <table class="border-b-2">
          <tr class="text-left border-b-2">
            <th class="py-2 text-xs md:text-base">{{ t('Product') }}</th>
            <th class="py-2 text-xs md:text-base">{{ t('Rate price') }}</th>
            <th class="py-2 text-xs md:text-base">{{ t('Amount') }}</th>
            <th class="py-2 text-xs md:text-base">{{ t('Total amount') }}</th>
          </tr>

          <tr v-for="(item, index) in confirm.services" :key="index">
            <td class="w-96 py-2 text-left text-xs md:text-base">
              {{ item.serviceName }}
            </td>
            <td class="w-60 py-2 text-left text-xs md:text-base">
              {{ item.ratePrice }}
            </td>
            <td class="w-60 py-2 text-left text-xs md:text-base">
              {{ item.mount }}
            </td>
            <td class="w-60 py-2 text-left text-xs md:text-base">
              {{ item.total }}
            </td>
          </tr>
        </table>

        <p class="mt-1 mb-20 text-left text-xs text-gray-500">
          {{ t('Date is caliculated as UTC') }}
        </p>
        <div class="flex justify-center md:justify-end items-center">
          <div class="md:mr-20">
            <div class="flex mb-6">
              <div class="">
                <h2 class="w-32 text-left mb-2">{{ t('Subtotal') }}：</h2>
                <h2 class="w-32 text-left mb-2">{{ t('Sales tax') }}：</h2>
                <h2 class="w-32 text-left mb-2 font-bold">
                  {{ t('Total amount with tax') }}:
                </h2>
              </div>
              <div class="">
                <p class="text-right mb-2">{{ confirm.total }}</p>
                <p class="text-right mb-2">{{ confirm.salesTax }}</p>
                <p class="text-right font-bold mb-2">
                  {{ confirm.totalWithTax }}
                </p>
              </div>
            </div>

            <button v-on:click="exec" class="bg-blue-800 text-white px-10 py-1">
              {{ t('Buy now') }}
            </button>

            <p class="pt-5 text-gray-700 text-xs w-52 text-left">
              {{
                t(
                  'Please prepare an email address and a credit card. Moving to stripe.'
                )
              }}
            </p>
          </div>
        </div>

        <div class="text-left mt-32">
          <h2 class="text-gray-700">{{ t('Qualitia.co.,Ltd') }}</h2>
          <p class="text-xs md:text-sm text-gray-500 mb-1">
            {{
              t(
                '3-11-10, Nihombashikayabacho, Chuo Ku, Tokyo To, 103-0025, Japan'
              )
            }}
          </p>
          <div class="text-xs md:text-sm flex text-gray-500">
            <p class="w-10">{{ t('Phone') }}:</p>
            <p>{{ t('+81-3-5623-2530') }}</p>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="text-gray-500 text-xl">
          {{ noService }}
        </p>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import * as Sentry from '@sentry/browser'
import { defineComponent, ref, PropType } from 'vue'
import { Confirm } from '@/api/customer/order/qt-dns/confirm'
import { useTranslation } from '@/locales'

export default defineComponent({
  props: {
    confirm: Object as PropType<Confirm | undefined>,
    noService: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const processing = ref(false)
    const { t } = useTranslation()

    const exec = () => {
      processing.value = true
      props
        .onClick()
        .then(() => (processing.value = false))
        .catch(() => (processing.value = false))
    }

    return {
      t,
      exec,
    }
  },
})
</script>
